<template>
  <div class="i-team-multi-picker">
    <div class="field-label">
      {{ label }}
    </div>
    <multiselect
      v-model="selected"
      label="name"
      trackBy="id"
      :options="sharedDrives"
      :multiple="false"
      :placeholder="placeholder"
      @search-change="onSearch"
      @select="onSelect"
      @remove="onRemove"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {listSharedDrives} from "@/services/api/google/recovery/shared-drive/sharedDrive";
import {defaultPagination} from "@/services/pagination";

export default {
  name: 'ISharedDrivePicker',
  components: {Multiselect},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Shared drive'
    },
    placeholder: {
      type: String,
      default: 'Search for shared drives here'
    },
    filter: {
      type: Object,
      default: {
        "statuses": [
          1, 2, 4
        ]
      }
    }
  },

  data() {
    return {
      selected: null,
      sharedDrives: []
    }
  },

  methods: {
    onSearch(searchTerm) {
      this.sharedDrives = [];
      this.loadSharedDrives(searchTerm)
    },
    loadSharedDrives(search = null) {
      listSharedDrives({
        pagination: {
          ...defaultPagination(),
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: this.filter
      })
        .then(({result, pagination}) => {
          this.sharedDrives = result;
        })
    },
    onSelect(data) {
      this.$emit('input', data)
    },
    onRemove(data) {
      this.$emit('input', null)
    }
  },

  created() {
    if (this.value?.status != 4) {
      this.selected = this.value;
    }

    this.loadSharedDrives();
  },
}
</script>

<style lang="scss">
@import "ITeamPicker.scss";
</style>
