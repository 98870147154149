function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};


function generate_api_url(params = null) {
  let URL = 'https://app-staging.idrivec2c.com/api';

  if (process.env.VUE_APP_API_URL) {
    URL = process.env.VUE_APP_API_URL;
  }

  if (params !== null) {
    return `${URL}${params}`
  }

  return URL

}

export {
  debounce,
  generate_api_url
}
