import {defaultPagination} from "@/services/pagination";
import {listBackups} from "@/services/api/app/office/recovery/backup";

const defaultState = () => ({
  backups: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'backupDate',
  },
  filter: {
    backupType: 4,
    providerType: 1,
  },
});

export default {
  namespaced: true,
  actions: {
    listBackups({commit, state}, {userIds, filter}) {
      return listBackups({
        pagination: state.pagination,
        userIds,
        filter: {
          ...state.filter,
          status: filter.status,
          userIds: filter.userIds
        }
      })
          .then(({result, pagination}) => {
            commit('setBackups', result)
            commit('setPagination', pagination)
            commit('setFilter', {
              ...state.filter,
              status: filter.status,
              userIds: filter.userIds
            })
          })
    },
  },

  mutations: {
    setBackups(state, backups) {
      state.backups = backups;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'backupDate',
      };
    },
    setFilter(state, filter) {
      state.filter = filter
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  state: defaultState(),
}
