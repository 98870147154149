import {GET, POST} from "@/services/gateway";

const getReport = (data) => {
  return POST('report/summary', data);
}

const getOtherEventsReport = (data) => {
  return POST('report/summary/other', data);
}

const listEventLogs = (data) => {
  return POST('report/events', data);
}

const exportEventLogs = (data) => {
  const currentDate = new Date();

  return POST('report/export', {
    ...data,
    TimeZoneOffset: currentDate.getTimezoneOffset()
  });
}

const getJobDetails = (id) => {
  return GET(`report/events/${id}`, )
}

export {
  getReport,
  getOtherEventsReport,
  listEventLogs,
  exportEventLogs,
  getJobDetails,
}
