const defaultPagination = () => {
    let rowsPerPage = 10;

    if (window.innerHeight > 900 && window.innerHeight < 1200) {
      rowsPerPage = 15
    } else if (window.innerHeight > 1201 && window.innerHeight < 1400) {
      rowsPerPage = 20
    } else if (window.innerHeight > 1401) {
        rowsPerPage = 25
    }

    return {
        pageNumber: 1,
        pageSize: rowsPerPage,
        sortBy: 'createdAt',
        sortDescending: true
    };
}

const latestOnePagination = {
  pageNumber: 1,
  pageSize: 1,
  sortBy: 'createdAt',
  sortDescending: true
};


const getAllRowsPagination = {
  pageNumber: 1,
  pageSize: 9999,
  sortBy: 'createdAt',
  sortDescending: true
};


export {
  latestOnePagination,
  defaultPagination,
  getAllRowsPagination
}
