/**
 * Generates initial form model for FormBuilder from config
 * @param config
 * @returns {*}
 */
const generateFormModel = config =>
  (
    config.rows.reduce((formModel, row) => (
        {
          ...formModel,
          ...row.blocks.reduce((fields, block) => ({
            ...fields,
            ...block.fields.reduce((model, field) => ({
              ...model,
              [field.name]: field.config ? [] : null //nested form
            }), {})
          }), {})
        }),
      {})
  );

/**
 * Returns list of all fields in a form
 * @param config
 * @returns {*}
 */
const getFormFields = config =>
  (
    config.rows.reduce((formModel, row) => ({
        ...formModel,
        ...row.blocks.reduce((fields, block) => ({
          ...fields,
          ...block.fields.reduce((model, field) => {
            if (field.config) {
              return {
                ...model,
                ...getFormFields(field.config)
              }
            } else
              return {
                ...model,
                [field.name]: field
              };
          }, {})
        }), {})
      }),
      {})
  );


export {
  generateFormModel,
  getFormFields
}
