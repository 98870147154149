import boxDrive from "@/store/app/box/recovery/boxDrive/drive";
import user from "@/store/app/box/recovery/user";


export default {
  namespaced: true,

  actions: {},

  modules: {
    user,
    boxDrive,
  }
}
