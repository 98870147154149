<template>
  <div class="i-user-multi-picker">

    <i-multi-picker
      :label-counter="currentCollection === 'exchange' ? 'seat(s)' : 'user(s)'"
      :label="label"
      :value="value"
      :fetch-options="fetchUsers"
      option-label="name"
      option-value="userId"
      @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {list as listMicrosoftUsers} from "@/services/api/app/seat-management/user";
import {listAllUsers} from "@/services/api/app/office/recovery/exchange/exchange";
import {list as listGoogleUsers} from "@/services/api/app/google/seat-management/user";
import {list as listDropboxUsers} from "@/services/api/app/dropbox/seat-management/user";
import {list as listBoxUsers} from "@/services/api/app/box/seat-management/user";
import IMultiPicker from "@/components/forms/IMultiPicker.vue";

export default {
  name: 'IUserMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Users'
    },
    placeholder: {
      type: String,
      default: 'Search all users'
    },
    providerTenant: {
      type: String,
      default: 'MICROSOFT'
    },
    currentCollection: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      selected: null,
      users: []
    }
  },

  methods: {
    fetchUsers({search}) {
      let list = listGoogleUsers
      if (this.providerTenant === 'MICROSOFT') {
        if (this.currentCollection === 'exchange') {
          list = listAllUsers;
        }
        list = listMicrosoftUsers
      } else if (this.providerTenant === 'DROPBOX') {
        list = listDropboxUsers
      } else if (this.providerTenant === 'BOX') {
        list = listBoxUsers
      }

      return list({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
        .then(({result}) => {
          return result
        })
    },
  },


}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
