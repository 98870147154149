import {POST, GET} from "@/services/gateway";

const list = () => {
  return GET('seat/backupsetings');
}

const updateAutoAdd = ({seatType, autoAdd}) => {
  return POST('seat/backupsetings', {
    seatType, autoAdd
  })
}


export {
  list,
  updateAutoAdd
}
