import {list, getById, getSummary, cancel, retry, listTenantJobsSummary, deleteJob} from "@/services/graphql/job";
import {defaultPagination} from "@/services/pagination";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  list: [],
  jobsSummaryList: [],
  jobsSummaryMap: {},
  map: {},
  summary: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'start_time',
    sortDescending: true
  },
  jobsSummaryPagination: defaultPagination(),
  jobsSummaryFilter: {},
  filter: {},
  summaryFilter: 'LAST_24_H',
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}) {
      return list({pagination: stripPagination(state.pagination), filter: state.filter})
        .then(({result, pagination}) => {
          commit('set', result)
          commit('setPagination', pagination)
        })
    },
    listJobsSummary({commit, state}) {
      return listTenantJobsSummary({pagination: stripPagination(state.jobsSummaryPagination), filter: state.jobsSummaryFilter})
        .then(({result, pagination}) => {
          commit('setJobsSummary', result)
          commit('setJobsSummaryPagination', pagination)
        })
    },
    getById({commit, state}, id) {
      return getById(id)
        .then(job => {
          commit('setInMap', job)
        })
    },
    getSummary({commit, state}, dateFrom) {
      return getSummary(dateFrom)
        .then(data => {
          commit('setSummary', data)
          commit('setSummaryFilter', dateFrom)
        })
    },
    cancel({dispatch}, data) {
      return cancel(data)
        .then(() => {
          dispatch('list')
        })
    },
    retry({dispatch}, data) {
      return retry(data)
        .then(() => {
          dispatch('list')
        })
    },
    deleteJob({dispatch}, data) {
      return deleteJob(data)
    },
  },

  mutations: {
    set(state, jobs) {
      state.list = jobs;

      state.map = jobs.reduce((acc, job) => ({
        ...acc,
        [job._id]: job
      }), {})
    },
    setJobsSummary(state, jobs) {
      state.jobsSummaryList = jobs;

      state.jobsSummaryMap = jobs.reduce((acc, job) => ({
        ...acc,
        [job.Id]: job
      }), {})
    },
    setInMap(state, job) {
      state.map = {
        ...state.map,
        [job._id]: {
          ...job
        }
      };
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        pageSize: state.pagination.pageSize,
        sortBy: 'start_time',
        sortDescending: true
      };
    },
    resetJobsSummaryPagination(state) {
      state.jobsSummaryPagination = defaultPagination();
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setJobsSummaryPagination(state, pagination) {
      state.jobsSummaryPagination = pagination;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSummaryFilter(state, summaryFilter) {
      state.summaryFilter = summaryFilter;
    },
    setJobsSummaryFilter(state, jobsSummaryFilter) {
      state.jobsSummaryFilter = jobsSummaryFilter;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  getters: {
    getById: (state) => (id) => {
      return state.map[id]
    }
  },

  state: defaultState()
}
