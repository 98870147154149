import {GET, POST} from "@/services/gateway";

const listUsers = ({pagination, search, filter}) => {
  return POST('google/user/list', {pagination, search, filter});
}

const getUserDetails = (id) => {
  return GET('google/calendar/user', {id})
}

const listSnapshots = ({userId, date, dateTo}) => {
  return POST('google/calendar/snapshot/list', {userId, date, dateTo})
}

const createBackup = (userId) => {
  return GET('google/calendar/backup', {userId})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, code}) => {
  return POST('google/calendar/export', {snapshotDateTime, sourceIds, searchTerm, selectedIds}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, code}) => {
  return POST('google/calendar/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds}, {code: code ? code : null});
}

export {
  listUsers,
  getUserDetails,
  listSnapshots,
  createBackup,
  exportSnapshot,
  restoreSnapshot
}
