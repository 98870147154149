import {
    createBackup,
    getUserDetails,
    listSnapshots
} from "@/services/api/app/office/recovery/exchange/exchange";
import mail from "@/store/app/office/recovery/exchange/data-set/mail"
import contact from "@/store/app/office/recovery/exchange/data-set/contact"
import calendar from "@/store/app/office/recovery/exchange/data-set/calendar"
import task from "@/store/app/office/recovery/exchange/data-set/task"
import exports from "@/store/app/office/recovery/exchange/exports";
import restores from "@/store/app/office/recovery/exchange/restores";
import backups from "@/store/app/office/recovery/exchange/backups";
import user from "@/store/app/office/recovery/exchange/user";

const defaultState = () => ({
  userMap: {},
});

export default {
  namespaced: true,
  actions: {
    getUserDetails({commit}, userId) {
      return getUserDetails(userId)
        .then(user => {
          commit('setUser', user);
          return user;
        })
    },
    createBackup({commit}, userId) {
      return createBackup(userId);
    },
    listSnapshots({commit}, {userId, date, dateTo}) {
      return listSnapshots({userId, date, dateTo})
    },
  },

  mutations: {
    setUser(state, user) {
      state.userMap = {
        ...state.userMap,
        [user.userId]: user
      };
    },
  },

  getters: {
    getUserById: (state) => (id) => {
      return state.userMap[id];
    },
  },

  state: defaultState(),

  modules: {
    mail,
    contact,
    calendar,
    task,
    exports,
    restores,
    backups,
    user:{...user}
  },

}
