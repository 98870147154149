import {list, create, update, generateNewTempPassword, logInAsUser} from "@/services/graphql/user";
import {defaultPagination} from "@/services/pagination";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  list: [],
  map: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'email',
    sortDescending: false
  }
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}) {
      return list({pagination: stripPagination(state.pagination)})
        .then(({result, pagination}) => {
          commit('set', result)
          commit('setPagination', pagination)
        })
    },
    create({state, dispatch}, data) {
      return create(data)
    },
    update({state, dispatch}, data) {
      return update(data)
    },
    generateNewTempPassword({state, dispatch}, id) {
      return generateNewTempPassword(id)
    },
    logInAsUser({state, dispatch}, {userId, accessLevel}) {
      return logInAsUser(userId, accessLevel)
    }
  },

  mutations: {
    set(state, users) {
      state.list = users;

      state.map = users.reduce((acc, user) => ({
        ...acc,
        [user.id]: user
      }), {})
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'email',
        sortDescending: false
      };
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },


  state: defaultState()
}
