import {listTenantCollectionData, listTenantCollections} from "@/services/graphql/databaseBrowser";
import {defaultPagination} from "@/services/pagination";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  tenantCollections: [],
  mongoQueryColumns: [],
  mongoQueryList: [],
  mongoQueryPagination: {
    ...defaultPagination(),
    sortBy: '_id',
    sortDescending: false
  },
  mongoQueryFilter: {}
});

export default {
  namespaced: true,

  actions: {
    listTenantCollections({commit, state}, tenantId) {
      return listTenantCollections(tenantId)
        .then((collections) => {
          commit('setTenantCollections', collections);
        })
    },
    listTenantCollectionData({commit, state}) {
      return listTenantCollectionData({pagination: stripPagination(state.mongoQueryPagination), filter: state.mongoQueryFilter})
        .then(({columns, data, pagination}) => {
          commit('setMongoQueryColumns', columns);
          commit('setMongoQueryList', data);
          commit('setMongoQueryPagination', pagination);
        })
    }
  },
  mutations: {
    setTenantCollections(state, tenantCollections) {
      state.tenantCollections = tenantCollections;
    },
    setMongoQueryColumns(state, columns) {
      state.mongoQueryColumns = columns;
    },
    setMongoQueryList(state, data) {
      state.mongoQueryList = data;
    },
    setMongoQueryFilter(state, filter) {
      state.mongoQueryFilter = filter;
    },
    setMongoQueryPagination(state, pagination) {
      state.mongoQueryPagination = pagination;
    },
    resetMongoQueryPagination(state) {
      state.mongoQueryPagination = {
        ...defaultPagination(),
        pageSize: state.mongoQueryPagination.pageSize,
        sortBy: '_id',
        sortDescending: false
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },
  state: defaultState()
}
