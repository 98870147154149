const iSourceTypeConverter = (str, item) => {
  const normalizedStr = str?.toUpperCase();
  const isSourceTypeMatch = item?.name === 'source_type' || item?.source_type === normalizedStr;

  switch (normalizedStr) {
    case 'SHARED_DRIVE':
      return isSourceTypeMatch ? 'Shared Drive' : 'Shared Drives';
    case 'SHARED_FOLDER':
      return isSourceTypeMatch ? 'Team Folder' : 'Team Folders';
    default:
      return str;
  }
};

export {
  iSourceTypeConverter
};
