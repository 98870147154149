const iEmptyData = (data, emptyData = 'NA') => {
  try {
    if (
      (Array.isArray(data) && !data.length) ||
      (typeof data === 'object') ||
      (!data.trim()) ||
      data === null ||
      data === 'null' ||
      data === undefined ||
      data === "undefined"
    ) {
      return emptyData;
    }

    return data;
  } catch (e) {
    return data;
  }
}

export {
  iEmptyData,
}
