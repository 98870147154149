import {list, changeStatus, getStatus, refresh, protectAll, unprotectAll, backupAll, exportSnapshot} from "@/services/api/app/dropbox/seat-management/sharedFolder";
import {defaultPagination} from "@/services/pagination";

const defaultState = () => ({
  sharedFolders: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null,
  filter: {
    statuses: null,
  },
  status: null
});

export default {
  namespaced: true,

  actions: {
    list({commit, state},) {
      return list({
        pagination: state.pagination,
        filter: state.filter,
        search: state.search
      })
        .then(({result, pagination}) => {
          const sharedFolders = result.reduce((acc, sharedFolder) => {
            return [
              ...acc,
              {
                ...sharedFolder,
                totalSize: sharedFolder?.folder?.size
              }
            ]
          }, [])

          commit('setSharedFolders', sharedFolders)
          commit('setPagination', pagination)
        })
    },
    getStatus({commit}) {
      return getStatus()
        .then(result => {
          commit('setStatus', result)
        })
    },
    refresh({commit}) {
      return refresh()
    },
    changeStatus({commit}, data) {
      return changeStatus(data)
    },
    backupAll() {
      return backupAll()
    },
    protectAll() {
      return protectAll();
    },
    unprotectAll() {
      return unprotectAll();
    },
    exportSnapshot({commit, state}, {filter, search, pagination}) {
      return exportSnapshot(filter, search, pagination)
    },
  },

  mutations: {
    setSharedFolders(state, sharedFolders) {
      state.sharedFolders = sharedFolders;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
      };
    },
    setSearch(state, search) {
      state.search = search;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setStatus(state, status) {
      state.status = status
    },
    setSharedFoldersStatus(state, {sharedFolder, status}) {
      state.sharedFolders = state.sharedFolders.map(u => {
        if (u.sharedFolderId === sharedFolder.sharedFolderId) {
          return {
            ...u,
            status: status.value
          }
        }

        return u;
      })
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },


  state: defaultState()
}
