<template>
  <div class="i-date-time">
    <div v-if="placeholder" class="field-label">
      {{ placeholder }}
    </div>
    <img src="../../assets/app/icons/calender-icon.svg" class="calendar-icon">
    <VueCtkDateTimePicker
      no-label
      :label="dateValue"
      :no-button-now="noButtonNow"
      :noClearButton="true"
      :value="dateValue"
      :no-header="true"
      @input="onInput"/>
  </div>
</template>

<script>

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import {formatISO} from 'date-fns'

export default {
  name: "IDateTime",
  components: {VueCtkDateTimePicker},
  props: {
    value: {
      type: [Date, String, Number],
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    maxDate: {
      type: [Date, String, Number],
    },
    minDate: {
      type: [Date, String, Number],
    },
    noButtonNow: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mask: 'MM.DD.YYYY, hh:mm:ss',
    }
  },

  computed: {
    dateValue() {
      const date = new Date(this.value);
      const result = formatISO(date)
      return result;
    },
    minDateValue() {
      // return date.formatDate(this.minDate, 'YYYY-MM-DD HH:mm');
      return this.minDate
    },
    maxDateValue() {
      // return date.formatDate(this.maxDate, 'YYYY-MM-DD HH:mm');
      return this.maxDate
    },
  },

  methods: {
    onInput(value) {
      const result = formatISO(new Date(value))
      this.$emit('input', result);
    },
  },


  created() {
    if (this.dateValue) {
      this.onInput(this.dateValue);
    }
  }

}
</script>

<style lang="scss">
@import "IDateTime";
</style>
