import {POST, GET} from "@/services/gateway";

const list = () => {
  return GET('google/shared-drive/backupsetings');
}

const updateAutoAdd = ({seatType, autoAdd}) => {
  return POST('google/shared-drive/backupsetings', {
    seatType, autoAdd
  })
}


export {
  list,
  updateAutoAdd
}
