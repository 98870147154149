<template>
  <div class="i-ellipsis-text">
    <i-tooltip v-if="text.length > charactersLength" :text="text">
      <template v-slot:tooltip-wrapper>
        <span v-if="showSlash" class="slash">/ </span> {{ text | iEllipsis(charactersLength) }}
      </template>
    </i-tooltip>

    <div v-else>
      <span v-if="showSlash">/</span>
      {{ text | iEllipsis }}
    </div>
  </div>
</template>

<script>
import ITooltip from "@/components/ITooltip.vue"

export default {
  name: "IEllipsisText",
  components: {ITooltip},
  props: {
    text: {
      type: String,
      required: true,
    },
    charactersLength: {
      type: Number,
    },
    showSlash: {
      type: Boolean,
      default: true,
    }
  },
}
</script>

<style lang="scss">
@import "IEllipsisText";
</style>
