import recovery from "@/store/app/office/recovery/recovery";
import stats from "@/store/app/office/stats";
import report from "@/store/app/office/report";
import userReport from "@/store/app/office/userReport";
import backups from "@/store/app/office/backups";
import allEventLogs from "@/store/app/office/activity-logs/allEventLogs";
import editDomain from "@/store/app/office/editDomain";
import backedUpItems from "@/store/app/office/activity-logs/backedUpItems";
import backupsByDay from "@/store/app/office/activity-logs/backupEventsByDay";
import backupItems from "@/store/app/office/activity-logs/backupItems"
import users from '@/store/app/office/seat-management/user'
import teams from '@/store/app/office/seat-management/team'
import sites from '@/store/app/office/seat-management/site'
import mailboxes from '@/store/app/office/seat-management/mailbox'
import backupSettings from '@/store/app/office/seat-management/backupsettings'

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    stats: stats,
    report,
    userReport,
    backups,
    allEventLogs,
    editDomain,
    backedUpItems,
    backupsByDay,
    backupItems,
    users,
    teams,
    sites,
    mailboxes,
    backupSettings,
  }
}
