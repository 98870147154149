import recovery from "@/store/app/google/recovery/recovery";
import users from './google/seat-management/user'
import sharedDrives from './google/seat-management/sharedDrive'
import stats from "@/store/app/google/stats";
import report from "@/store/app/google/report";
import userReport from "@/store/app/google/userReport";
import backups from "@/store/app/google/backups";
import backupSettings from "@/store/app/google/seat-management/backupsettings";
import backupSettingsSharedDrive from "@/store/app/google/seat-management/shared-drive/backupsettings";
import allEventLogs from "@/store/app/google/activity-logs/allEventLogs";
import editDomain from "@/store/app/google/editDomain";
import backedUpItems from "@/store/app/google/activity-logs/backedUpItems";
import backupsByDay from "@/store/app/google/activity-logs/backupEventsByDay";
import backupItems from "@/store/app/google/activity-logs/backupItems";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    users: users,
    sharedDrives: sharedDrives,
    stats: stats,
    report,
    userReport,
    backups,
    backupSettings,
    backupSettingsSharedDrive,
    allEventLogs,
    editDomain,
    backedUpItems,
    backupsByDay,
    backupItems
  }
}
