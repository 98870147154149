import Vue from "vue";
import Notifications from "vue-notification";
import notifyMixin from "@/mixins/notify";

Vue.use(Notifications)

Vue.mixin(notifyMixin);

const globalMixin = {

  computed: {
    $providerTenant() {
      return this.$store.state.providerTenant
    },
    $providerUrl() {
      return this.$store.state.providerUrl
    },
    $collection() {
      return this.$store.state.collection
    },
    $isDark() {
      if (!window.localStorage.getItem("idrive-theme") || window.localStorage.getItem("idrive-theme") === 'default') {
        return false;
      } else if (window.localStorage.getItem("idrive-theme") === 'dark') {
        return true
      }

      return false
    }
  }
};
Vue.mixin(globalMixin)
