import {
  getUserDetails,
  listSnapshots,
  createBackup,
  exportSnapshot,
  restoreSnapshot
} from "@/services/api/app/google/recovery/calendar/calendar";
import files from "@/store/app/google/recovery/calendar/data-set/file";
import exports from "@/store/app/google/recovery/calendar/exports";
import restores from "@/store/app/google/recovery/calendar/restores";
import backups from "@/store/app/google/recovery/calendar/backups";
import user from "@/store/app/google/recovery/calendar/user";

const defaultState = () => ({
  userMap: {},
});

export default {
  namespaced: true,
  actions: {
    getUserDetails({commit}, userId) {
      return getUserDetails(userId)
        .then(user => {
          commit('setUser', user);
          return user;
        })
    },
    createBackup({commit}, userId) {
      return createBackup(userId);
    },
    listSnapshots({commit}, {userId, date, dateTo}) {
      return listSnapshots({userId, date, dateTo});
    },
    exportSnapshot({commit}, data) {
      return exportSnapshot(data);
    },
    restoreSnapshot({}, data) {
      return restoreSnapshot(data);
    }
  },

  mutations: {
    setUser(state, user) {
      state.userMap = {
        ...state.userMap,
        [user.userId]: user
      };
    },
  },

  getters: {
    getUserById: (state) => (id) => {
      return state.userMap[id];
    },
  },

  state: defaultState(),

  modules: {
    files,
    exports,
    restores,
    backups,
    user,
  }
}
