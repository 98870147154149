const iEnumConverter = (str) => {
  try {
    return str.replace('_', ' ')
  } catch (e) {
    return str
  }
}

export {
  iEnumConverter,
}
