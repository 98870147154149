<template>
  <div class="i-search-organisation-picker">
    <div v-if="label" class="field-label">
      {{ label }}
    </div>

    <multiselect
      v-model="selectedValue"
      trackBy="Id"
      :options="organisations"
      :multiple="false"
      :placeholder="placeholder"
      :loading="isLoading"
      :custom-label="customLabel"
      @search-change="onSearch"
      @select="onSelect"
      @remove="onRemove"
    >
      <template v-slot:clear>
        <font-awesome-icon
          v-if="value && clearable"
          class="clear-btn btn-icon icon-clickable"
          icon="fa-duotone fa-xmark"
          @click.stop="clearAll()"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {listOnlyTenants} from "@/services/graphql/tenant";
import {defaultPagination} from "@/services/pagination";
import {debounce} from "@/services/common";

export default {
  name: 'ISearchOrganisationPicker',
  components: {Multiselect},
  props: {
    value: {},
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Search organisations'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    searchOnlyById: {
      type: Boolean,
      default: false
    },
    includeSuperAdmins: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      selectedValue: null,
      organisations: [],
      isLoading: false,
      searchTerm: null
    }
  },

  computed: {
    customLabel() {
      return (option) => {
        if (this.includeSuperAdmins) {
          return `${option.superAdminFullName}, Tenant id: ${option.Id}, User id: ${option.superAdmin.user.Id}, Email: ${option.superAdmin.user.EmailAddress}, Total jobs (last 24 hours): ${option.totalJobs}`;
        } else {
          return option.Id;
        }
      }
    }
  },

  methods: {
    onSearch: debounce(async function (searchTerm) {
      if (this.searchOnlyById && isNaN(searchTerm)) {
        this.organisations = [];
        this.isLoading = false;
        return;
      }

      this.searchTerm = searchTerm;
      this.isLoading = true;
      this.organisations = [];
      await this.loadOrganisations(searchTerm);
      this.isLoading = false;
    }, 1000),
    loadOrganisations(search = null) {
      return listOnlyTenants({
        pagination: {
          ...defaultPagination(),
          pageSize: 30,
          sortBy: this.searchOnlyById ? 'Id' : 'Name',
          sortDescending: false
        },
        filter: {
          search,
          includeSuperAdmin: this.includeSuperAdmins,
          includeJobReports: this.includeSuperAdmins,
        }
      })
        .then(({result}) => {
          if (this.includeSuperAdmins) {
            this.organisations = result.map(organisation => ({
              ...organisation,
              superAdminFullName: `${organisation.superAdmin.user.Name} ${organisation.superAdmin.user.Surname}`,
            }));
          } else {
            this.organisations = result;
          }

          this.isLoading = false;
        })
    },
    onSelect(data) {
      this.$emit('input', data.Id)
    },
    onRemove() {
      this.selectedValue = null;
      this.$emit('input', null)
    },
    clearAll() {
      this.selectedValue = null;
      this.$emit('input', null)
    }
  },

  created() {
    this.selectedValue = {Id: parseInt(this.value) } || null;
    this.loadOrganisations();
  }
}
</script>

<style lang="scss">
@import "ISearchOrganisationPicker.scss";
</style>
