import {defaultPagination} from "@/services/pagination";
import {deleteCustomBackupQueues, listCustomBackupQueues} from "@/services/graphql/rabbitmq";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  list: [],
  map: {},
  pagination: {
    ...defaultPagination(),
    pageSize: 100,
    pageNumber: 1,
    sortBy: 'messages_unacknowledged'
  },
  filter: {}
});

export default {
  namespaced: true,

  actions: {
    listCustomBackupQueues({commit, state}) {
      return listCustomBackupQueues({pagination: stripPagination(state.pagination)})
        .then(({result, pagination}) => {
          commit('setCustomBackupQueues', result)
          commit('setCustomBackupQueuesPagination', pagination)
        })
    },
    deleteCustomBackupQueues({commit, state}, queues) {
      return deleteCustomBackupQueues(queues)
    }
  },

  mutations: {
    setCustomBackupQueues(state, queues) {
      state.list = queues;

      state.map = queues.reduce((acc, queue) => ({
        ...acc,
        [queue.name]: queue
      }), {})
    },
    resetCustomBackupQueuesPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        pageSize: state.pagination.pageSize,
      };
    },
    setCustomBackupQueuesPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  state: defaultState()
}
