import {
  getStats,
  getFinishedBackup
} from "@/services/api/app/google/stats";

const defaultState = () => ({
  stats: {},
  finishedBackup: false,
});

export default {
  namespaced: true,

  actions: {
    getStats({commit}, data) {
      return getStats(data)
        .then(stats => {
          commit('setStats', stats);
          return stats;
        })
    },
    getFinishedBackup({commit}) {
      return getFinishedBackup()
        .then(finishedBackup => {
          commit('setFinishedBackup', finishedBackup);
          return finishedBackup;
        })
    },
  },

  mutations: {
    setStats(state, stats) {
      state.stats = stats
    },
    setFinishedBackup(state, finishedBackup) {
      state.finishedBackup = finishedBackup
    },
  },

  getters: {
    getTotalProtectedData: (state) => () => {
      const
        driveStats = state.stats?.driveStats?.totalData || 0,
        contactStats = state.stats?.contactStats?.totalData || 0,
        googleMailStats = state.stats?.googleMailStats?.totalData || 0,
        calendarStats = state.stats?.calendarStats?.totalData || 0,
        googleSharedDriveStats = state.stats?.googleSharedDriveStats?.totalData || 0,

        totalData = driveStats + contactStats + googleMailStats + calendarStats + googleSharedDriveStats

      return totalData
    },
  },

  state: defaultState(),
}
