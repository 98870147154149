import {getBackupItems} from "@/services/api/app/office/reports/backedupItems";
import {formatISO} from "date-fns";

const defaultState = () => ({
  backupItems: [],
});

export default {
  namespaced: true,
  actions: {
    getBackupItems({commit}) {
      return getBackupItems()
        .then((data) => {
          commit('setBackups', data)
        })
    },
  },

  mutations: {
    setBackups(state, backupItems) {
      state.backupItems = backupItems;
    },
  },

  getters: {
    getFilteredBackupItemsByDay: (state) => () => {
      let successBackups = [],
        failedBackups = []
      let now = new Date(),
        dayMs = 86400000,  // 1 day in milliseconds
        lastDays = [];

      for (let i = 0; i < 7; i++) {
        let formatIsoDate = formatISO(new Date(now.getTime() - (i * dayMs)));
        lastDays.push(formatIsoDate.slice(0, 10));
      }

      lastDays.reverse()

      const filteredBackupItemsByDay = state.backupItems.reduce((acc, date) => {
        for (let i = 0; i < lastDays.length; i++) {
          let currDate = lastDays[i];
          const localDate = new Date(date.date);

          let month = localDate.getMonth() + 1;
          if (month < 10) {
            month = '0' + month;
          }

          let customDate = localDate.getDate();
          if (customDate < 10) {
            customDate = '0' + customDate;
          }

          let customHours = localDate.getHours();
          if (customHours < 10) {
            customHours = '0' + customHours;
          }

          let customMinutes = localDate.getMinutes();
          if (customMinutes < 10) {
            customMinutes = '0' + customMinutes;
          }

          let customSeconds = localDate.getSeconds();
          if (customSeconds < 10) {
            customSeconds = '0' + customSeconds;
          }

          const localDateString = localDate.getFullYear() + '-' + month + '-' + customDate + 'T' + customHours + ':' + customMinutes + ':' + customSeconds + 'Z';

          const dateParts = localDateString.split(currDate);

          if (dateParts.length > 1) {

            if (acc[currDate]) {
              return {
                ...acc,
                [currDate]: {
                  ...acc[currDate],
                  failItems: date.failItems,
                  successItems: date.successItems,
                  date: date?.date,
                }
              }
            } else {
              successBackups.push(date.successItems)
              failedBackups.push(date.failItems)
              return {
                ...acc,
                [currDate]: {
                  failItems: date.failItems,
                  successItems: date.successItems,
                  date: new Date(date.date).getDate(),
                }
              }
            }
          }
        }

        return {
          ...acc,
          lastDays,
          successBackups,
          failedBackups,
        }
      }, {})

      return filteredBackupItemsByDay;
    },
  },

  state: defaultState(),
}
