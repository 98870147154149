let notifyMixin = {
    methods: {
        $notifySuccess: function (notificationMessage = 'Your data was successfully updated') {
            this.$notify({
                group: 'foo',
                type: 'success-notification toast-notification',
                text: `<img src="${require('@/assets/app/icons/tick.svg')}" class="notify-icon"> ${notificationMessage}`,
                closeOnClick: true,
            })
        },
        $notifyError: function (notificationMessage = 'Something went wrong..Please try again later') {
            this.$notify({
                group: 'foo',
                type: 'error-notification toast-notification',
                text: `<img src="${require('@/assets/app/icons/failuer-icon.svg')}" class="notify-icon"> ${notificationMessage}`,
                closeOnClick: true,
            })
        },
        $getFirstGqlErrorMessage: function (err, defaultMessage = 'Нешто не е во ред. Пробајте повторно подоцна.') {
            return err.graphQLErrors && err.graphQLErrors.length ?
                err.graphQLErrors[0].message : defaultMessage;
        },
        $getGqlError: function (err, defaultMessage = 'Нешто не е во ред. Пробајте повторно подоцна.') {
            console.log("err", err)
            return err.graphQLErrors ? err.graphQLErrors[0] : {message: defaultMessage};
        },
        $getFirstGqlErrorKey: function (err) {
            if (!err) {
                return ''
            }
            return err.graphQLErrors && err.graphQLErrors.length ?
                err.graphQLErrors[0].key : '';
        },
        $handelRedirectForError: function (err) {

            let error = this.$getGqlError(err);
            if (error && error.statusCode === 404) {
                return this.$router.push({name: '404'})
            }
        }
    },
}


export default notifyMixin;
