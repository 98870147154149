<template>
  <div class="form-builder-field-error">
    <div v-if="!validationField[errorKey]"
         v-for="errorKey in Object.keys(validationField.$params)"
         class="error-msg">
      <span v-if="errors && errors && errors[errorKey]"> {{ errors[errorKey] }}</span>
      <span v-else-if="errorKey === 'required'">{{ fieldLabel.replace('*', '') }} is required</span>
      <span v-else> Something is wrong... </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormBuilderFieldError",
  props: {
    validationField: {
      type: Object
    },
    fieldLabel: {
      type: String
    },
    errors: {}
  }
}
</script>

<style lang="scss">
@import "FormBuilderFieldError";
</style>
