import {ApolloClient, InMemoryCache} from '@apollo/client/core';
import {lazy} from "apollo-link-lazy";

const apolloClient = new ApolloClient({
  link: lazy(() => {
    return import('./apollo-mgmt.js');
  }),
  cache: new InMemoryCache({
    // addTypename: false
  })
});

export {
  apolloClient,
}

export default ({router, state}) => {
  return apolloClient;
}
