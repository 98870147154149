<template>
  <div class="i-team-multi-picker">

    <i-multi-picker
        label-counter="team(s)"
        :label="label"
        :value="value"
        :fetch-options="fetchTeams"
        option-label="name"
        option-value="teamId"
        @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {listTeams} from "@/services/api/app/office/recovery/team/team";
import {defaultPagination} from "@/services/pagination";
import IMultiPicker from "@/components/forms/IMultiPicker.vue";

export default {
  name: 'ITeamMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: "Teams"
    },
    placeholder: {
      type: String,
      default: 'Search all teams'
    },
  },

  data() {
    return {
      selected: null,
      teams: []
    }
  },

  methods: {
    fetchTeams({search}) {

      return listTeams({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
          .then(({result}) => {
            return result
          })
    },
  },

}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
