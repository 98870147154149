<template>
  <div class="i-mongo-logs-collection-picker">
    <div class="field-label">
      {{ label }}
    </div>

    <multiselect
      :value="value"
      label="name"
      trackBy="name"
      :options="collections"
      :multiple="false"
      :searchable="false"
      :placeholder="placeholder"
      :loading="isLoading"
      :disabled="disabled"
      @input="onSelect"
    >
      <template v-slot:clear>
        <font-awesome-icon
          v-if="value && clearable"
          class="clear-btn btn-icon icon-clickable"
          icon="fa-duotone fa-xmark"
          @click.stop="clear()"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {listMongoLogs} from "@/services/graphql/mongoLogs";

export default {
  name: "IMongoLogsCollectionPicker",
  components: {Multiselect},
  props: {
    value: {},
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Choose'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      selectedValue: null,
      collections: [],
      isLoading: false,
      searchTerm: null,
      input: {
        pagination: {
          pageNumber: 1,
          pageSize: 0
        },
        filter: {
          type: 'collection'
        }
      }
    }
  },
  methods: {
    loadCollections() {
      return listMongoLogs(this.input)
        .then(collections => {
          this.collections = collections.result.flatMap(log => (log.collection !== null ? [{name: log.collection}] : []))
        })
    },
    onSelect(value) {
      this.$emit('input', value)
    },
    clear() {
      this.$emit('input', null)
    }
  },
  created() {
    this.loadCollections()
  }
};
</script>

<style lang='scss'>
@import "IMongoLogsCollectionPicker";
</style>
