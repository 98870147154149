import {GET, POST} from "@/services/gateway";

const getBackedupItems = () => {
  return GET('dashboard/dropbox/backup-events');
}

const list = (data) => {
  return POST('dashboard/dropbox/backup-events', data);
}

const getBackupItems = () => {
  return GET('dashboard/dropbox/backup-items');
}

export {
  getBackedupItems,
  list,
  getBackupItems,
}
