import Vue from 'vue'
import VueRouter from 'vue-router'
import {getRoutes} from "@/router/mgmt/routes";
import Store from "@/store/mgmt";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior: () => ({x: 0, y: 0}),
  routes: getRoutes(Store),
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE
});

export default router;
