import drive from "@/store/app/google/recovery/drive/drive";
import gmail from "@/store/app/google/recovery/gmail/gmail";
import calendar from "@/store/app/google/recovery/calendar/calendar";
import user from "@/store/app/google/recovery/user";
import contact from "@/store/app/google/recovery/contact/contact";
import sharedDrive from "@/store/app/google/recovery/shared-drive/sharedDrive";


export default {
  namespaced: true,

  actions: {},

  modules: {
    user,
    drive,
    gmail,
    calendar,
    contact,
    sharedDrive
  }
}
