import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import GET_TENANT_DETAILS from '../../graphql/tenant/getTenantDetails.gql';
import GET_PROVIDER_SUMMARY from '../../graphql/tenant/getProviderSummary.gql';

const getTenantDetails = (id) => apolloClient
  .query({
    query: GET_TENANT_DETAILS,
    variables: {
      id,
      input: {
        tenantId: id
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {tenantDetails, totalSeats, totalBackedUpData}}) => {
    return {
      ...tenantDetails,
      totalSeats: totalSeats.totalSeats,
      totalBackedUpData: totalBackedUpData.totalSize
    }
  })

const getProviderSummary = (id, provider) => {
  const isMicrosoft = provider === 'microsoft';
  const isGoogle = provider === 'google';
  const isDropbox = provider === 'dropbox';
  const isBox = provider === 'box';

  return apolloClient.query({
    query: GET_PROVIDER_SUMMARY,
    variables: {
      input: {
        tenantId: id,
        provider: provider
      },
      isGoogle,
      isMicrosoft,
      isDropbox,
      isBox,
    },
    fetchPolicy: 'no-cache'
  }).then(({ data: { providerSummary } }) => providerSummary);
};

export {
  getTenantDetails,
  getProviderSummary
}
