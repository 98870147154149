<template>
  <button
      class="i-button"
      :class="{[btnClass]: true, [iconPosition]: true, [bold]: true}"
      :disabled="isDisabled"
      :type="type"
      @click="submit"
  >
    <font-awesome-icon
        v-if="icon"
        class="btn-icon"
        :icon="changeIcon(icon)"
    />
    <img
        v-if="iconImage"
        class="btn-icon"
        :class="{'loading-class' : isLoading}"
        alt="icon"
        :src="changeImg(iconImage)"
    >

    <span :class="{'is-loading': loading}">
      {{ label }}
    </span>

    <div v-if="loading" class="loader-button">
      <div class="loader spin">
        <div class="spin__blocker"></div>
        <div class="spin__bottom-left"></div>
        <div class="spin__bottom-right"></div>
        <div class="spin__top-left"></div>
      </div>
    </div>

  </button>
</template>

<script>
export default {
  name: "IButton",
  props: {
    label: {
      type: String,
      default: "Save"
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'submit'
    },
    icon: {
      type: String
    },
    iconImage: {
      type: String
    },
    btnClass: {
      type: String,
      default: 'md'
    },
    iconPosition: {
      type: String,
      default: 'left' // left / right
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    clickAction: {
      type: Function,
    },
    bold: {
      type: String, // bold
    },
  },

  data: () => ({actionInProgress: false}),

  computed: {
    loading() {
      return this.isLoading || this.actionInProgress
    },
  },

  methods: {
    submit($ev) {
      if (this.isDisabled || this.loading) {
        return false;
      }
      this.$emit('click',$ev)
      if (this.clickAction) {
        this.actionInProgress = true;
        try {
          return this.clickAction()
              .finally(() => this.actionInProgress = false)
        } catch (e) {
          this.actionInProgress = false
        }
      }
    },

    changeImg(img) {
      if (this.isDisabled) {
        return require(`../../assets/app/icons-disabled/${img}`)
      } else {
        return require(`../../assets/app/${img}`)
      }
    },

    changeIcon(icon) {
      return `fa-duotone fa-${icon}`
    }
  }
}
</script>

<style lang="scss">
@import "IButton";
</style>
