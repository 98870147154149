import {GET, POST, DELETE} from "@/services/gateway";

const list = ({pagination}) => {
  return POST('user/list', {pagination})
}

const create = ({firstName, lastName, email, password, confirmPassword}) => {
  return POST('account/create', {firstName, lastName, email, password, confirmPassword})
}

const deleteAdministrator = (userId) => {
  return DELETE('account/delete', userId)
}

const refresh = () => {
  return GET('user/refresh')
}

export {
  list,
  refresh,
  create,
  deleteAdministrator,
}
