<template>
  <div v-if="pagination.totalRecords" class="i-table-pagination">
    <div class="rows-per-page">
      <!--      Rows per page-->
      <select :value="pagination.pageSize" @input="onChange">
        <option value="5">5</option>
        <option value="7">7</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="pagination-container">

      <div
          class="prev prev-next"
          :class="{'disabled' : !canShowPrev}"
          @click="showPrev"
      >
        <font-awesome-icon icon="fa-duotone fa-angle-left"/>
      </div>
      <div class="showing">
        {{ showingStart }} - {{ showingEnd }} of {{ pagination.totalRecords ? pagination.totalRecords : '0' }}
      </div>
      <div
          class="next prev-next"
          :class="{'disabled' : !canShowNext}"
          @click="showNext"
      >
        <font-awesome-icon icon="fa-duotone fa-angle-right"/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ITablePagination',
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },

  computed: {
    showingStart() {
      return this.pagination.pageSize * this.pagination.pageNumber - this.pagination.pageSize + 1
    },
    showingEnd() {
      let end = this.pagination.pageSize * this.pagination.pageNumber;
      if (end > this.pagination.totalRecords) {
        return this.pagination.totalRecords
      }

      return this.pagination.pageSize * this.pagination.pageNumber
    },
    canShowPrev() {
      return this.pagination.pageNumber > 1
    },
    canShowNext() {
      return this.pagination.pageNumber < this.pagination.totalPages;
    },
  },
  methods: {
    onChange(e) {
      this.$emit('updatePagination', {
        ...this.pagination,
        pageNumber: 1,
        pageSize: e.target.value
      })
    },

    showPrev() {
      if (this.canShowPrev) {
        this.$emit('updatePagination', {
          ...this.pagination,
          pageNumber: this.pagination.pageNumber - 1
        })
      }

    },
    showNext() {
      if (this.canShowNext) {
        this.$emit('updatePagination', {
          ...this.pagination,
          pageNumber: this.pagination.pageNumber + 1
        })
      }

    }
  }


}
</script>

<style lang="scss">
@import "ITablePagination.scss";
</style>
