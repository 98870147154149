import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import LIST_TENANT_COLLECTIONS from '../../graphql/database-browser/listTenantCollections.gql'
import LIST_TENANT_COLLECTION_DATA from '../../graphql/database-browser/listTenantCollectionData.gql'
import {stripTypenames} from "@/services/graphql/common";

const listTenantCollections = (tenantId) => apolloClient
  .query({
    query: LIST_TENANT_COLLECTIONS,
    variables: {tenantId: tenantId.toString()},
    fetchPolicy: 'no-cache'
  }).then(({data: {listTenantCollections: {collections}}}) => {
    return collections.length
      ? collections.map(item => ({label: item, value: item})).sort((a, b) => a.label.localeCompare(b.label))
      : [];
  })

const listTenantCollectionData = ({pagination, filter: {tenantId, collectionName, filter, sort}}) => {
  console.log('service input', tenantId, collectionName, filter, sort, pagination);
  return apolloClient
    .query({
      query: LIST_TENANT_COLLECTION_DATA,
      variables: {
        input: {
          tenantId: tenantId.toString(),
          collectionName,
          filter: {
            filter: JSON.parse(filter),
            sort: JSON.parse(sort)
          },
          pagination
        }
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {listTenantCollectionData: {columns, data, pagination}}}) => {
      return {
        columns,
        data,
        pagination: stripTypenames(pagination)
      }
    })
}

export {
  listTenantCollections,
  listTenantCollectionData
}
