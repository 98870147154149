import {GET, POST} from "@/services/gateway";

const listMails = ({pagination, userIds, snapshotDateTime, folderId, searchTerm}) => {
  return POST('exchange/mail/search', {pagination, userIds, snapshotDateTime, folderId, searchTerm});
}

const listBreadcrumps = ({userId, folderId, snapshotDate}) => {
  return POST('exchange/mail/breadcrumbs', {userId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, outputFormat, code}) => {
  return POST('exchange/mail/export', {snapshotDateTime, sourceIds, searchTerm, selectedIds, outputFormat}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, code}) => {
  return POST('exchange/mail/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds}, {code: code ? code : null})
}

const getEmailDetails = (id) => {
  return GET(`exchange/mail/view?mailId=${id}`, )
}


export {
  listMails,
  listBreadcrumps,
  exportSnapshot,
  restoreSnapshot,
  getEmailDetails,
}
