<template>
  <div class="i-bubble-color" :style="{ background: [generateColor] }">
    <slot name="bubble-content"></slot>
  </div>
</template>

<script>

export default {
  name: 'IBubbleColor',
  computed: {
    generateColor() {
      let colors = ['#FF9595', '#7CC1DB', '#8DA2D4', '#AD92C8', '#E7AEC4', '#EEC294', '#DD8A8A']
      return colors[Math.floor(Math.random() * colors.length)]
    },
  },
}
</script>

<style lang="scss">
@import "IBubbleColor";
</style>
