import recovery from "@/store/app/dropbox/recovery/recovery";
import dropboxUsers from '@/store/app/dropbox/seat-management/user'
import sharedFolders from '@/store/app/dropbox/seat-management/sharedFolder'
import stats from "@/store/app/dropbox/stats";
import report from "@/store/app/dropbox/report";
import userReport from "@/store/app/dropbox/userReport";
import backups from "@/store/app/dropbox/backups";
import backupSettings from "@/store/app/dropbox/seat-management/backupsettings";
import backupSettingsSharedFolder from "@/store/app/dropbox//seat-management/shared-folder/backupsettings";
import allEventLogs from "@/store/app/dropbox/activity-logs/allEventLogs";
import editDomain from "@/store/app/dropbox/editDomain";
import backedUpItems from "@/store/app/dropbox/activity-logs/backedUpItems";
import backupsByDay from "@/store/app/dropbox/activity-logs/backupEventsByDay";
import backupItems from "@/store/app/dropbox/activity-logs/backupItems";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    users: dropboxUsers,
    sharedFolders: sharedFolders,
    stats: stats,
    report,
    userReport,
    backups,
    backupSettings,
    backupSettingsSharedFolder,
    allEventLogs,
    editDomain,
    backedUpItems,
    backupsByDay,
    backupItems
  }
}
