import {signIn, activateAccount, signOut} from "../../services/graphql/auth";

const defaultState = () => ({
  accessToken: null,
  refreshToken: null,
  self: {},
  jobsSearchTerms: [],
  MAX_SEARCH_TERMS: 20
})

export default {
  namespaced: true,

  actions: {
    signIn({commit}, data) {
      return signIn(data)
        .then(result => {
          commit('setSignInData', result)
        })
    },
    activateAccount({commit}, input) {
      return activateAccount(input)
    },
    signOut({commit}) {
      return signOut()
        .finally(() => {
          commit('resetState')
        })
    }
  },

  mutations: {
    setSignInData(state, {accessToken, refreshToken, user}) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.self = user;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setJobsSearchTerm(state, jobSearchTerm) {
      if (state.jobsSearchTerms.length === state.MAX_SEARCH_TERMS) {
        state.jobsSearchTerms.shift();
      }

      state.jobsSearchTerms.push(jobSearchTerm);
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  getters: {
  },

  state: defaultState()
}
