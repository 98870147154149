import { changeStatus, getStatus, list, protectAll, unprotectAll, refresh, backupAll, exportSnapshot } from "@/services/api/app/seat-management/mailbox";
import { defaultPagination, } from "@/services/pagination";

const defaultState = () => ({
  mailboxes: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null,
  filter: {
    statuses: null,
  },
  status: null
});

export default {
  namespaced: true,

  actions: {
    list({ commit, state },) {
      return list({
        pagination: state.pagination,
        filter: state.filter,
        search: state.search
      })
        .then(({ result, pagination }) => {
          commit('setMailboxes', result)
          commit('setPagination', pagination)
        })
    },
    getStatus({ commit }) {
      return getStatus()
        .then(result => {
          commit('setStatus', result)
        })
    },
    refresh({ commit }) {
      return refresh()
    },
    changeStatus({ commit }, data) {
      return changeStatus(data)
    },
    backupAll() {
      return backupAll()
    },
    protectAll() {
      return protectAll();
    },
    unprotectAll() {
      return unprotectAll();
    },
    exportSnapshot({ commit, state }, { filter, search, pagination }) {
      return exportSnapshot(filter, search, pagination)
    },
  },

  mutations: {
    setMailboxes(state, mailboxes) {
      state.mailboxes = mailboxes;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
      };
    },
    setSearch(state, search) {
      state.search = search;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setStatus(state, status) {
      state.status = status
    },
    setMailboxStatus(state, { mailbox, status }) {
      state.mailboxes = state.mailboxes.map(m => {
        if (m.userId === mailbox.userId) {
          return {
            ...m,
            status: status.value
          }
        }

        return u;
      })
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },


  state: defaultState()
}
