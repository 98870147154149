import { GET, POST } from "@/services/gateway";

const listUsers = ({ pagination, search, filter }) => {
  return POST('seat/user/list', { pagination, search, filter });
}

const listAllUsers = ({ pagination, search, filter }) => {
  return POST('seat/user/list/all', { pagination, search, filter });
}

const getUserDetails = (id) => {
  return GET('exchange/user', { id })
}

const listSnapshots = ({ userId, date, dateTo }) => {
  return POST('exchange/user/snapshots', { userId, date, dateTo })
}

const createBackup = (userId) => {
  return POST('exchange/createbackup', { userId })
}

export {
  listUsers,
  listAllUsers,
  createBackup,
  getUserDetails,
  listSnapshots,
}
