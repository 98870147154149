import {library} from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import {
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faBars,
  faCaretDown,
  faCircle,
  faCircleInfo,
  faDownload,
  faDownLong,
  faEllipsis,
  faEllipsisVertical,
  faMagnifyingGlass,
  faQuestion,
  faUpload,
  faUpLong,
  faXmark,
  faCircleXmark,
  faCircleCheck,
  faFolder,
  faList,
  faClockRotateLeft,
  faTableLayout,
  faCircleSmall,
  faGear,
  faCircleQuestion,
  faFileExport,
  faUserGroup,
  faRotateRight,
  faHouseChimney,
  faDisplayArrowDown,
  faCloudArrowUp,
  faTrashCan,
  faCalendarDays,
  faSearch,
} from '@fortawesome/pro-duotone-svg-icons'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Vue from "vue";

library.add(faUpLong)
library.add(faDownLong)
library.add(faAngleDown)
library.add(faCaretDown)
library.add(faBars)
library.add(faXmark)
library.add(faCircleXmark)
library.add(faEllipsis)
library.add(faEllipsisVertical)
library.add(faQuestion)
library.add(faCircleInfo)
library.add(faCircle)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faMagnifyingGlass)
library.add(faDownload)
library.add(faUpload)
library.add(faCircleCheck)
library.add(faFolder)
library.add(faList)
library.add(faClockRotateLeft)
library.add(faTableLayout)
library.add(faCircleSmall)
library.add(faGear)
library.add(faCircleQuestion)
library.add(faFileExport)
library.add(faUserGroup)
library.add(faRotateRight)
library.add(faHouseChimney)
library.add(faDisplayArrowDown)
library.add(faCloudArrowUp)
library.add(faTrashCan)
library.add(faCalendarDays)
library.add(faSearch)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
