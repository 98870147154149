<template>
  <div class="i-mongo-logs-tenants-picker">
    <div class="field-label">
      {{ label }}
    </div>

    <multiselect
      :value="value"
      label="name"
      trackBy="name"
      :options="tenants"
      :multiple="false"
      :placeholder="placeholder"
      :loading="isLoading"
      :disabled="disabled"
      @search-change="onSearch"
      @input="onSelect"
    >
      <template v-slot:clear>
        <font-awesome-icon
          v-if="value && clearable"
          class="clear-btn btn-icon icon-clickable"
          icon="fa-duotone fa-xmark"
          @click.stop="clear()"
        />
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {debounce} from "@/services/common";
import {listMongoLogs} from "@/services/graphql/mongoLogs";

export default {
  name: "IMongoLogsTenantsPicker",
  components: {Multiselect},
  props: {
    value: {},
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Choose'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedValue: null,
      tenants: [],
      isLoading: false,
      searchTerm: null,
      input: {
        pagination: {
          pageNumber: 1,
          pageSize: 0
        },
        filter: {
          type: 'tenant'
        }
      }
    }
  },
  methods: {
    loadTenants(search = null) {
      return listMongoLogs({pagination: {...this.input.pagination}, filter: {...this.input.filter, search}})
        .then(tenants => {
          this.tenants = tenants.result.flatMap(log => (log.tenant !== null ? [{name: log.tenant}] : []));
          this.isLoading = false;
        })
    },
    onSearch(searchTerm) {
      this.searchTerm = searchTerm;
      this.isLoading = true;
      this.tenants = [];
      this.loadTenants(searchTerm);
    },
    onSelect(value) {
      this.$emit('input', value)
    },
    clear() {
      this.$emit('input', null)
    }
  },
  created() {
    this.onSearch = debounce(this.onSearch, 300);
    this.loadTenants()
  }
};
</script>

<style lang='scss'>
@import "IMongoLogsTenantsPicker";
</style>
