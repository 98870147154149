import {defaultPagination,} from "@/services/pagination";
import {changeStatus, getStatus, list, protectAll, unprotectAll, refresh, backupAll, exportSnapshot} from "@/services/api/app/seat-management/site";


const defaultState = () => ({
  sites: [],
  sitesMap: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null,
  filter: {
    statuses: null,
  },
  status: null
});

export default {
  namespaced: true,

  actions: {
    list({commit, state},) {
      return list({
        pagination: state.pagination,
        filter: state.filter,
        search: state.search
      })
        .then(({result, pagination, search, statuses}) => {
          commit('setSeats', result)
          commit('setPagination', pagination)
        })
    },
    getStatus({commit}) {
      return getStatus()
        .then(result => {
          commit('setStatus', result)
        })
    },
    refresh({commit}) {
      return refresh()
    },
    backupAll({commit}) {
      return backupAll()
    },
    protectAll({commit}){
      return protectAll();
    },
    unprotectAll({commit}){
      return unprotectAll();
    },
    changeStatus({commit}, data) {
      return changeStatus(data)
    },
    exportSnapshot({commit}, {filter, search, pagination}) {
      return exportSnapshot(filter, search, pagination);
    },
  },

  mutations: {
    setSeats(state, sites) {
      state.sites = sites;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
      };
    },
    setSearch(state, search) {
      state.search = search;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setStatus(state, status) {
      state.status = status
    },
    setSitesStatus(state, {user, status}) {
      state.sites = state.sites.map(u => {
        if (u.siteId === user.siteId) {
          return {
            ...u,
            status: status.value
          }
        }

        return u;
      })
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },


  state: defaultState()
}
