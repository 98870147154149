import {listBackupsOverview} from "@/services/api/app/office/backupsOverview";

const defaultState = () => ({
    activeBackups: [],
    totalActiveBackups: '',
    queuedBackups: [],
    totalQueueBackups: '',
    filter: {
        ProviderType: 1,
    }
});

export default {
    namespaced: true,
    actions: {
        listBackupsOverview({commit, state}) {
            return listBackupsOverview(state.filter)
                .then((result) => {
                    commit('setActiveBackups', result.activeBackups)
                    commit('setTotalActiveBackups', result.totalActiveBackups)
                    commit('setQueuedBackups', result.queuedBackups)
                    commit('setQueuedActiveBackups', result.totalQueueBackups)
                })
        },
    },

    mutations: {
        setActiveBackups(state, backups) {
            state.activeBackups = backups.map(b => {
              let backupModule = b.module;
              if (b.sourceType == 3 && b.module == 3) {
                backupModule = 9;
              }

              return {
                ...b,
                module: backupModule
              }
            });
        },
        setTotalActiveBackups(state, backups) {
            state.totalActiveBackups = backups;
        },
        setQueuedBackups(state, backups) {
            state.queuedBackups = backups.map(b => {
              let backupModule = b.module;
              if (b.sourceType == 3 && b.module == 3) {
                backupModule = 9;
              }

              return {
                ...b,
                module: backupModule
              }
            });
        },
        setQueuedActiveBackups(state, backups) {
            state.totalQueueBackups = backups;
        },
    },

    state: defaultState(),
}
