import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import LIST_CUSTOM_BACKUP_QUEUES from "../../graphql/rabbitmq/listCustomBackupQueues.gql"
import DELETE_CUSTOM_BACKUP_QUEUES from "../../graphql/rabbitmq/deleteCustomBackupQueues.gql"
import {stripTypenames} from "@/services/graphql/common";

const listCustomBackupQueues = ({pagination}) => {
  return apolloClient
    .query({
      query: LIST_CUSTOM_BACKUP_QUEUES,
      variables: {
        input: {
          pagination: {
            ...pagination,
            pageSize: parseInt(pagination.pageSize)
          }
        }
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {queues}}) => stripTypenames(queues))
}

const deleteCustomBackupQueues = (queues) => {
  return apolloClient
    .mutate({
      mutation: DELETE_CUSTOM_BACKUP_QUEUES,
      variables: {
        input: {
          queues
        }
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {response}}) => response)
}

export {
  listCustomBackupQueues,
  deleteCustomBackupQueues
}
