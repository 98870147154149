import {
  createBackup,
  getSiteDetails,
  listSites,
  listSnapshots
} from "@/services/api/app/office/recovery/share-point/sharePoint";
import {defaultPagination} from "@/services/pagination";
import drive from './data-set/drive'
import list from './data-set/list'
import exports from "@/store/app/office/recovery/share-point/exports";
import restores from "@/store/app/office/recovery/share-point/restores";
import backups from "@/store/app/office/recovery/share-point/backups";

const defaultState = () => ({
  sites: [],
  siteMap: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null
});

export default {
  namespaced: true,

  actions: {
    listSites({commit, state},) {
      return listSites({
        pagination: state.pagination,
        search: state.search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
        .then(({result, pagination}) => {
          commit('setSites', result)
          commit('setPagination', pagination)
        })
    },
    getSiteDetails({commit}, siteId) {
      return getSiteDetails(siteId)
        .then(site => {
          commit('setSite', site);
          return site;
        })
    },
    createBackup({commit}, siteId) {
      return createBackup(siteId);
    },
    listSnapshots({commit}, {siteId, date, dateTo}) {
      return listSnapshots({siteId, date, dateTo})
    }
  },

  mutations: {
    setSites(state, sites) {
      state.sites = sites;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
      };
    },
    setSearch(state, search) {
      state.search = search;
    },
    setSite(state, site) {
      state.siteMap = {
        ...state.siteMap,
        [site.siteId]: site
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  getters: {
    getSiteById: (state) => (id) => {
      return state.siteMap[id];
    },
  },

  state: defaultState(),

  modules: {
    drive,
    list,
    exports,
    restores,
    backups,
  },

}
