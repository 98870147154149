import {listMongoLogs} from "@/services/graphql/mongoLogs";
import {defaultPagination} from "@/services/pagination";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  list: [],
  map: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'timestamp',
    sortDescending: true
  },
  filter: {}
});

export default {
  namespaced: true,

  actions: {
    listMongoLogs({commit, state}) {
      return listMongoLogs({pagination: stripPagination(state.pagination), filter: state.filter})
        .then(({result, pagination}) => {
          commit('setMongoLogs', result)
          commit('setMongoLogsPagination', pagination)
        })
    }
  },

  mutations: {
    setMongoLogs(state, logs) {
      state.list = logs;

      state.map = logs.reduce((acc, log) => ({
        ...acc,
        [log.Id]: log
      }), {})
    },
    setMongoLogsInMap(state, log) {
      state.map = {
        ...state.map,
        [log._id]: {
          ...log
        }
      };
    },
    resetMongoLogsPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        pageSize: state.pagination.pageSize,
        sortBy: 'timestamp',
        sortDescending: true
      };
    },
    setMongoLogsPagination(state, pagination) {
      state.pagination = pagination;
    },
    setMongoLogsFilter(state, filter) {
      state.filter = filter;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  getters: {
    getMongoLogsById: (state) => (id) => {
      return state.map[id]
    }
  },

  state: defaultState()
}
