<template>
  <div v-if="isLoading" class="i-loader">
    <div class="loader spin">
      <div class="spin__blocker"></div>
      <div class="spin__bottom-left"></div>
      <div class="spin__bottom-right"></div>
      <div class="spin__top-left"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ILoader",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss">
@import "ILoader";
</style>
