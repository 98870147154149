import {POST} from "@/services/gateway";

const listSnapshotDetails = ({userIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('google/gmail/search', {userIds, folderId, snapshotDateTime, pagination, searchTerm, allMail: true})
}

const listBreadcrumps = ({userId, folderId, snapshotDate}) => {
  return POST('google/gmail/breadcrumbs', {userId, folderId, snapshotDate})
}


export {
  listSnapshotDetails,
  listBreadcrumps
}
