import {POST, GET, DELETE} from "@/services/gateway";

const listExport = ({pagination, sourceType, dataTypes, dataProvider = 'GOOGLE'}) => {
  return POST('recovery/export/list', {pagination, sourceType, dataTypes, dataProvider})
}

const deleteExport = (id) => {
  return DELETE(`recovery/export`, id)
}

const downloadExport = (id) => {
  return GET(`recovery/export/${id}/download`)
}

const cancelExport = (id, module) => {
  return GET(`google/${module}/export/${id}/cancel`)
}

export {
  listExport,
  downloadExport,
  deleteExport,
  cancelExport
}
