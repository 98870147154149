import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import SIGN_IN from '../../graphql/auth/signIn.gql';
import SIGN_OUT from '../../graphql/auth/signOut.gql';
import REFRESH_ACCESS_TOKEN from "../../graphql/auth/refreshAccessToken.gql";
import ACTIVATE_ACCOUNT from "../../graphql/auth/activateAccount.gql";

const signIn = ({email, password}) =>
  apolloClient.mutate({
    mutation: SIGN_IN,
    variables: {
      input: {email, password}
    }
  }).then(({data}) => data.signInResponse)

const activateAccount = ({email,oldPassword, password}) =>
  apolloClient.mutate({
    mutation: ACTIVATE_ACCOUNT,
    variables: {
      input:{email,oldPassword, password}
    }
  });

const signOut = () =>
  apolloClient.mutate({
    mutation: SIGN_OUT,
  })

const refreshAccessToken = (refreshToken) => {
  return apolloClient.mutate({
    mutation: REFRESH_ACCESS_TOKEN,
    variables: {
      input: {
        refreshToken
      }
    }
  }).then(({data}) => data)
    .then(({tokens}) => tokens);
};

export {
  signIn,
  activateAccount,
  signOut,
  refreshAccessToken
}
