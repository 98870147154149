import {
  getSharedFolderDetails,
  listSnapshots,
  createBackup,
  listSharedFolders,
  exportSnapshot,
  restoreSnapshot
} from "@/services/api/dropbox/recovery/shared-folder/sharedFolder";
import {defaultPagination} from "@/services/pagination";
import files from "@/store/app/dropbox/recovery/shared-folder/data-set/file";
import exports from "@/store/app/dropbox/recovery/shared-folder/exports";
import restores from "@/store/app/dropbox/recovery/shared-folder/restores";
import backups from "@/store/app/dropbox/recovery/shared-folder/backups";
import user from "@/store/app/dropbox/recovery/shared-folder/user";

const defaultState = () => ({
  sharedFolders: [],
  sharedFolderMap: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null
});

export default {
  namespaced: true,
  actions: {
    listSharedFolders({commit, state},) {
      return listSharedFolders({
        pagination: state.pagination,
        search: state.search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
        .then(({result, pagination}) => {
          commit('setSharedFolders', result)
          commit('setPagination', pagination)
        })
    },
    getSharedFolderDetails({commit}, sharedFolderId) {
      return getSharedFolderDetails(sharedFolderId)
        .then(sharedFolder => {
          commit('setSharedFolder', sharedFolder);
          return sharedFolder;
        })
    },
    createBackup({commit}, sharedFolderId) {
      return createBackup(sharedFolderId);
    },
    listSnapshots({commit}, {sharedFolderId, date, dateTo}) {
      return listSnapshots({sharedFolderId, date, dateTo});
    },
    exportSnapshot({commit}, data) {
      return exportSnapshot(data);
    },
    restoreSnapshot({}, data) {
      return restoreSnapshot(data);
    },
  },

  mutations: {
    setSharedFolders(state, sharedFolders) {
      state.sharedFolders = sharedFolders;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state, {keepSort}) {
      if (keepSort) {
        state.pagination = {
          ...defaultPagination(),
          sortBy: state.pagination.sortBy,
          sortDescending: state.pagination.sortDescending,
        }
      } else {
        state.pagination = {
          ...defaultPagination(),
          sortBy: 'name',
          sortDescending: false
        };
      }

    },
    setSearchFiles(state, search) {
      state.search = search;
    },
    setSharedFolder(state, sharedFolder) {
      state.sharedFolderMap = {
        ...state.sharedFolderMap,
        [sharedFolder.sharedFolderId]: sharedFolder
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  getters: {
    getSharedFolderById: (state) => (id) => {
      return state.sharedFolderMap[id];
    },
  },

  state: defaultState(),

  modules: {
    files,
    exports,
    restores,
    backups,
    user
  },
}
