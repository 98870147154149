import dropboxDrive from "@/store/app/dropbox/recovery/dropboxDrive/drive";
import user from "@/store/app/dropbox/recovery/user";
import sharedFolder from "@/store/app/dropbox/recovery/shared-folder/sharedFolder";


export default {
  namespaced: true,

  actions: {},

  modules: {
    user,
    dropboxDrive,
    sharedFolder
  }
}
