import Vue from 'vue'
import VueRouter from 'vue-router'
import {getRoutes} from "@/router/app/routes";
import Store from "@/store/app";


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


Vue.use(VueRouter)


const router = new VueRouter({
  scrollBehavior: () => ({x: 0, y: 0}),
  routes: getRoutes(Store),

  // Leave these as they are and change in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE
})

export default router;
