import {GET, POST} from "@/services/gateway";

const listUsers = ({pagination, search, filter}) => {
  return POST('seat/user/list', {pagination, search, filter});
}

const listExport = ({pagination, search}) => {
  return POST('recovery/onedrive/list', {pagination, search});
}

const listRestore = ({pagination, search}) => {
  return POST('recovery/onedrive/list', {pagination, search});
}

const createBackup = (userId) => {
  return GET('recovery/onedrive/backup', {userId})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, code}) => {
  return POST('recovery/onedrive/export', {snapshotDateTime, sourceIds, searchTerm, selectedIds}, {code: code ? code : null})
}

const getUserDetails = (id) => {
  return GET('recovery/onedrive/user', {id})
}
// CHECK & REMOVE
const listSnapshotDetails = ({userId, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('recovery/onedrive/search', {userId, folderId, snapshotDateTime, pagination, searchTerm})
}
// CHECK & REMOVE
const listBreadcrumps = ({userId, folderId, snapshotDate}) => {
  return POST('recovery/onedrive/breadcrumbs', {userId, folderId, snapshotDate})
}

const listSnapshots = ({userId, date, dateTo}) => {
  return POST('recovery/onedrive/snapshot/list', {userId, date, dateTo})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, code}) => {
  return POST('recovery/onedrive/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds}, {code: code ? code : null})
}

export {
  listUsers,
  listExport,
  listRestore,
  createBackup,
  getUserDetails,
  listSnapshots,
  listBreadcrumps,
  exportSnapshot,
  restoreSnapshot
}
