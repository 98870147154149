import {defaultPagination} from "@/services/pagination";
import {listExport, deleteExport, downloadExport, cancelExport} from "@/services/api/app/dropbox/recovery/export/export";

const defaultState = () => ({
  exports: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'initiated_at'
  },
});

export default {
  namespaced: true,
  actions: {
    listExport({commit, state}) {
      return listExport({
        pagination: state.pagination,
        sourceType: 'USER',
        dataTypes: ['DRIVE'],
        dataProvider: 'DROPBOX',
      })
        .then(({result, pagination}) => {
          commit('setExports', result)
          commit('setPagination', pagination)
        })
    },
    downloadExport({commit, state}, {id}) {
      return downloadExport(id)
    },
    deleteExport({commit, state}, {id}) {
      return deleteExport(id)
    },
    cancelExport({commit, state}, {id}) {
      return cancelExport(id)
    },
  },

  mutations: {
    setExports(state, exports) {
      state.exports = exports;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },

    resetPagination(state, {keepSort}) {
      if (keepSort) {
        state.pagination = {
          ...defaultPagination(),
          sortBy: state.pagination.sortBy,
          sortDescending: state.pagination.sortDescending,
        }
      } else {
        state.pagination = {
          ...defaultPagination(),
          sortBy: 'name',
          sortDescending: false
        };
      }
    },

    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  state: defaultState(),
}
