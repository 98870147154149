import {getBackedupItems, list} from "@/services/api/app/google/reports/backedupItems";
import {defaultPagination} from "@/services/pagination";
import {formatISO} from "date-fns";

const defaultState = () => ({
  backedUpItems: [],
  detailsBackedUpItems: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'snapshotDate',
  },
});

export default {
  namespaced: true,
  actions: {
    getBackedupItems({commit}) {
      return getBackedupItems()
        .then((data) => {
          commit('setBackups', data)
        })
    },
    list({commit, state}, data) {
      return list({
        ...data,
        pagination: state.pagination
      })
        .then(({result, pagination}) => {
          commit('setBackudUpItems', result)
          commit('setPagination', pagination)
        })
    },
  },

  mutations: {
    setBackups(state, backedUpItems) {
      state.backedUpItems = backedUpItems;
    },
    setBackudUpItems(state, detailsBackedUpItems) {
      state.detailsBackedUpItems = detailsBackedUpItems;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'snapshotDate',
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  getters: {
    getFilteredItems: (state) => () => {
      let now = new Date(),
        dayMs = 86400000,  // 1 day in milliseconds
        lastDays = [];

      for (let i = 0; i < 7; i++) {
        let formatIsoDate = formatISO(new Date(now.getTime() - (i * dayMs)));
        lastDays.push(formatIsoDate.slice(0, 10));
      }

      const filteredBackupItems = state.backedUpItems.reduce((acc, date) => {
        for (let i = 0; i < lastDays.length; i++) {
          let currDate = lastDays[i];
          const localDate = new Date(date.date);

          let month = localDate.getMonth() + 1;
          if (month < 10) {
            month = '0' + month;
          }

          let customDate = localDate.getDate();
          if (customDate < 10) {
            customDate = '0' + customDate;
          }

          let customHours = localDate.getHours();
          if (customHours < 10) {
            customHours = '0' + customHours;
          }

          let customMinutes = localDate.getMinutes();
          if (customMinutes < 10) {
            customMinutes = '0' + customMinutes;
          }

          let customSeconds = localDate.getSeconds();
          if (customSeconds < 10) {
            customSeconds = '0' + customSeconds;
          }

          const localDateString = localDate.getFullYear() + '-' + month + '-' + customDate + 'T' + customHours + ':' + customMinutes + ':' + customSeconds + 'Z';

          const dateParts = localDateString.split(currDate);

          if (dateParts.length > 1) {
            let timeParsed = dateParts[1].replace('T', '').replace('Z', '')

            let hour = timeParsed.split(':')[0];
            let minute = timeParsed.split(':')[1];
            let minutes = (parseInt(hour) * 60) + parseInt(minute);

            if (acc[currDate]) {
              return {
                ...acc,
                [currDate]: {
                  ...acc[currDate],
                  [minutes]: {
                    hour: timeParsed,
                    failItems: date.failItems,
                    successItems: date.successItems,
                    date: date.date,
                    minutes: minutes,
                  }
                }
              }
            } else {
              return {
                ...acc,
                [currDate]: {
                  [minutes]: {
                    hour: timeParsed,
                    failItems: date.failItems,
                    successItems: date.successItems,
                    date: new Date(date?.date).toLocaleString(),
                    minutes: minutes,
                  }
                }
              }
            }
          }
        }

        return {
          ...acc
        }
      }, {})
      return filteredBackupItems;
    }
  },

  state: defaultState(),
}
