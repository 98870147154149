import {defaultPagination} from "@/services/pagination";
import {fetchReportsSummary, listReports} from "@/services/graphql/sizeReport";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  list: [],
  filter: {},
  summary: {},
  pagination: {
    ...defaultPagination(),
    pageNumber: 1,
    sortBy: 'userId',
    sortDescending: false
  },

});

export default {
  namespaced: true,

  actions: {
    listReports({commit, state}) {
      return listReports({pagination: stripPagination(state.pagination), filter:state.filter})
        .then(({result, pagination}) => {
          commit('setReports', result)
          commit('setPagination', pagination)
        })
    },
    fetchReportsSummary({commit, state}) {
      return fetchReportsSummary()
        .then(summary => {
          commit('setSummary', summary)
        })
    }
  },

  mutations: {
    setReports(state, reports) {
      state.list = reports;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        pageSize: state.pagination.pageSize,
        sortBy: 'userId',
        sortDescending: false
      };
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  state: defaultState()
}
