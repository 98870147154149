import {GET, POST} from "@/services/gateway";

const list = ({pagination, search, filter}) => {
  return POST('google/shared-drive/list', {pagination, search, filter});
}

const getStatus = () => {
  return GET('google/shared-drive/status');
}

const refresh = () => {
  return GET('google/shared-drive/refresh');
}

const backupAll = () => {
  return GET('google/shared-drive/backup/all')
}

const protectAll = () => {
  return POST('google/shared-drive/protect');
}

const unprotectAll = () => {
  return POST('google/shared-drive/unprotect');
}

const changeStatus = ({status, id}) => {
  return POST('google/shared-drive/status/change', {status, id});
}

const exportSnapshot = (filter, search, pagination) => {
  const currentDate = new Date();

  return POST('google/shared-drive/export', {filter, search, pagination, TimeZoneOffset: currentDate.getTimezoneOffset()})
}

export {
  list,
  refresh,
  getStatus,
  protectAll,
  unprotectAll,
  backupAll,
  changeStatus,
  exportSnapshot
}
