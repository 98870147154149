<template>
  <div class="i-team-multi-picker">

    <i-multi-picker
        label-counter="team folder(s)"
        :label="label"
        :value="value"
        :fetch-options="fetchSharedFolders"
        option-label="name"
        option-value="id"
        @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {listSharedFolders} from "@/services/api/dropbox/recovery/shared-folder/sharedFolder";
import IMultiPicker from "@/components/forms/IMultiPicker.vue";

export default {
  name: 'ISharedFolderMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: "Team folders"
    },
    placeholder: {
      type: String,
      default: 'Search all team folders'
    },
  },

  data() {
    return {
      selected: null,
      sharedFolders: []
    }
  },

  methods: {
    fetchSharedFolders({search}) {

      return listSharedFolders({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
          .then(({result}) => {
            return result
          })
    },
  },

}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
