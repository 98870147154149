import {POST} from "@/services/gateway";

const listSnapshotDetails = ({sharedDriveIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('google/shared-drive-snapshot/search', {sharedDriveIds, folderId, snapshotDateTime, pagination, searchTerm})
}

const listBreadcrumps = ({sharedDriveId, folderId, snapshotDate}) => {
  return POST('google/shared-drive-snapshot/breadcrumbs', {sharedDriveId, folderId, snapshotDate})
}


export {
  listSnapshotDetails,
  listBreadcrumps,
}
