import {POST} from "@/services/gateway";

const list = ({pagination, search, filter}) => {
  return POST('report/provider/seats', {
    pagination: pagination,
    search: search,
    filter: filter,
    provider: 1
  });
}

export {
  list,
}
