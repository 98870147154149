const iCapitalizeString = (str) => {
  try {
    return str[0].toUpperCase() + str.slice(1).toLowerCase()
  } catch (e) {
    return str
  }
}

export {
  iCapitalizeString,
}
