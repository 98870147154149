import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import LIST_MONGO_LOGS from '../../graphql/mongo-logs/listMongoLogs.gql'
import LIST_MONGO_LOGS_FILTER_DATA from '../../graphql/mongo-logs/listMongoLogsFilterData.gql'
import LIST_MONGO_LOGS_SUMMARY_STATS from '../../graphql/mongo-logs/listMongoLogsSummaryStats.gql'
import {stripTypenames} from "@/services/graphql/common";

const listMongoLogs = ({pagination, filter}) => {
  return apolloClient
    .query({
      query: LIST_MONGO_LOGS,
      variables: {
        input: {
          pagination: {
            ...pagination,
            pageSize: parseInt(pagination.pageSize)
          },
          filter
        }
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {mongoLogs}}) => mongoLogs)
}

const listMongoLogsFilterData = (columnName) => {
  return apolloClient
    .query({
      query: LIST_MONGO_LOGS_FILTER_DATA,
      variables: {
        input: columnName
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {result}}) => result)
}

const listMongoLogsSummaryStats = () => {
  return apolloClient
    .query({
      query: LIST_MONGO_LOGS_SUMMARY_STATS,
      fetchPolicy: 'no-cache'
    }).then(({data: data}) => stripTypenames(data))
}

export {
  listMongoLogs,
  listMongoLogsFilterData,
  listMongoLogsSummaryStats
}
