import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import user from './user'
import job from './job'
import tenant from './tenant'
import tenantJob from "./tenantJob";
import mongoLogs from "./mongoLogs";
import rabbitmq from "./rabbitmq";
import sizeReport from "./sizeReport";
import databaseBrowser from "./databaseBrowser";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const Store = new Vuex.Store({
  modules: {
    auth,
    user,
    job,
    tenant,
    tenantJob,
    mongoLogs,
    rabbitmq,
    sizeReport,
    databaseBrowser
  },
  plugins: [createPersistedState({
    key: 'idrive-mgmt',
    paths: ['auth'],
  })],
})

export default Store;

export {Store} ;

