import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import UPDATE_SEATS_SUMMARY from '../../graphql/seat-summary/updateSeatsSummary.gql';
import GET_SEATS_SUMMARY from '../../graphql/seat-summary/getSeatsSummary.gql';

const updateSeatsSummary = () => apolloClient
  .mutate({
    mutation: UPDATE_SEATS_SUMMARY
  }).then(({data: {updateSeatsSummary}}) => updateSeatsSummary)

const getSeatsSummary = (dateFrom) => apolloClient
  .query({
    query: GET_SEATS_SUMMARY,
    variables: {
      input: {
        dateFrom
      }
    },
    fetchPolicy: 'no-cache'
  }).then(({data: {data}}) => data)

export {
  updateSeatsSummary,
  getSeatsSummary,
}
