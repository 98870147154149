import {POST, GET} from "@/services/gateway";

const register = ({firstName, lastName, businessEmail, CompanyName, CompanySize, JobFunction, JobLevel, country, postalCode, password, confirmPassword, region, AcceptTerms}) => {
  return POST('account/register',
    {firstName, lastName, businessEmail, CompanyName, CompanySize, JobFunction, JobLevel, country, postalCode, password, confirmPassword, region, AcceptTerms: true}
  )
}

const authenticate = ({email, password}) => {
  return POST('account/authenticate', {email, password})
}

const getSelf = () => {
  return GET('user/self')
}

const googleConnect = ({adminEmail, serviceAccount}) => {
  return POST('Tenant/google/connect', {adminEmail, serviceAccount})
}

const generateBusinessMicrosoftUrl = () => {
  return GET('tenant/generate-business-microsoft-url')
}

const generatePersonalMicrosoftUrl = () => {
  return GET('tenant/generate-personal-microsoft-url')
}

const generatePersonalGoogleReauthUrl = () => {
  return GET('tenant/generate-personal-google-reauth-url')
}

const generatePersonalMicrosoftReauthUrl = () => {
  return GET('tenant/generate-personal-microsoft-reauth-url')
}

const generateDropboxUrl = (levelType) => {
  return GET(`tenant/dropbox/connect?levelType=${levelType}`)
}

const generateBoxUrl = (levelType) => {
  return GET(`tenant/box/connect?levelType=${levelType}`)
}


export {
  register,
  authenticate,
  getSelf,
  googleConnect,
  generateDropboxUrl,
  generateBoxUrl,
  generateBusinessMicrosoftUrl,
  generatePersonalMicrosoftUrl,
  generatePersonalMicrosoftReauthUrl,
  generatePersonalGoogleReauthUrl,
}
