import {list, getById, getSummary, cancel, retry} from "@/services/graphql/job";
import {defaultPagination} from "@/services/pagination";
import {stripPagination} from "@/services/graphql/common";

const defaultState = () => ({
  list: [],
  map: {},
  summary: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'start_time',
    sortDescending: true
  },
  filter: {},
  summaryFilter: 'LAST_24_H',
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}) {
      return list({pagination: stripPagination(state.pagination), filter: state.filter})
        .then(({result, pagination}) => {
          commit('set', result)
          commit('setPagination', pagination)
        })
    },
    getById({commit, state}, id) {
      return getById(id)
        .then(job => {
          commit('setInMap', job)
        })
    },
    getSummary({commit, state}, dateFrom) {
      return getSummary(dateFrom)
        .then(data => {
          commit('setSummary', data)
          commit('setSummaryFilter', dateFrom)
        })
    },
    cancel({dispatch}, data) {
      return cancel(data)
        .then(() => {
          dispatch('list')
        })
    },
    retry({dispatch}, data) {
      return retry(data)
        .then(() => {
          dispatch('list')
        })
    },
  },

  mutations: {
    set(state, jobs) {
      state.list = jobs;

      state.map = jobs.reduce((acc, job) => ({
        ...acc,
        [job._id]: job
      }), {})
    },
    setInMap(state, job) {
      state.map = {
        ...state.map,
        [job._id]: {
          ...job
        }
      };
    },
    setSummary(state, summary) {
      state.summary = summary;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        pageSize: state.pagination.pageSize,
        sortBy: 'start_time',
        sortDescending: true
      };
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setFilter(state, filter) {
      state.filter = {...state.filter,...filter};
    },
    setSummaryFilter(state, summaryFilter) {
      state.summaryFilter = summaryFilter;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  getters: {
    getById: (state) => (id) => {
      return state.map[id]
    }
  },

  state: defaultState()
}
