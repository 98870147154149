import {apolloClient as apolloClient} from "../../boot/mgmt/apollo";
import {stripTypenames} from "@/services/graphql/common";
import FETCH_REPORTS_SUMMARY from "../../graphql/size-report/fetchUserProviderReportsSummary.gql"
import LIST_REPORTS from "../../graphql/size-report/listReports.gql";

const fetchReportsSummary = () => {
  return apolloClient
    .query({
      query: FETCH_REPORTS_SUMMARY,
      variables: {
        input: {
          providers: [1, 2]
        }
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {summary}}) => stripTypenames(summary))
}

const listReports = ({pagination, filter}) => {
  return apolloClient
    .query({
      query: LIST_REPORTS,
      variables: {
        input: {
          pagination,
          filter
        }
      },
      fetchPolicy: 'no-cache'
    }).then(({data: {reports}}) => stripTypenames(reports))
}

export {
  fetchReportsSummary,
  listReports
}
