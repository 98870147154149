const getRoutes = store => {

  const checkAuth = (to, from, next) => {
    if (!store.state.auth.accessToken) {
      next({name: 'SignInPage'});
    } else {
      return next();
    }
  };

  const checkNotAuth = (to, from, next) => {
    if (!store.state.auth.accessToken) {
      next();
    } else {
      next({name: 'DashboardPage'});
    }
  };

  const routes = [
    {
      path: '/auth',
      component: () => import('../../layout/AuthLayout'),
      beforeEnter: checkNotAuth,
      children: [
        {
          path: 'sign-up',
          name: 'SignUpPage',
          component: () => import('../../pages/app/auth/SignUpPage')
        },
        {
          path: 'sign-in',
          name: 'SignInPage',
          component: () => import('../../pages/mgmt/auth/SignInPage')
        }
      ]
    },
    {
      path: '/',
      beforeEnter: checkAuth,
      component: () => import('../../layout/mgmt/AppLayout'),
      redirect: {name: 'DashboardPage'},
      children: [
        {
          path: '',
          redirect: {name: 'DashboardPage'}
        },
        {
          path: 'dashboard',
          name: 'DashboardPage',
          meta: {activeMenu: 'dashboard'},
          component: () => import('../../pages/mgmt/DashboardPage'),
        },
        {
          path: 'users',
          name: 'UsersPage',
          meta: {activeMenu: 'users'},
          component: () => import('../../pages/mgmt/UsersPage')
        },
        {
          path: 'jobs',
          name: 'JobsPage',
          meta: {activeMenu: 'jobs'},
          component: () => import('../../pages/mgmt/JobsPage')
        },
        {
          path: 'tenants',
          name: 'TenantsPage',
          meta: {activeMenu: 'tenants'},
          component: () => import('../../pages/mgmt/TenantsPage')
        },
        {
          path: 'tenants/:tenantId/',
          name: 'TenantDetailsPage',
          meta: {activeMenu: 'tenants'},
          component: () => import('@/pages/mgmt/TenantDetailsPage.vue'),
          props: true,
        },
        {
          path: 'jobs-summary',
          name: 'JobsSummaryPage',
          meta: {activeMenu: 'jobsSummary'},
          component: () => import('@/pages/mgmt/JobsSummaryPage.vue'),
        },
        {
          path: 'mongo-logs',
          name: 'MongoLogsPage',
          meta: {activeMenu: 'mongoLogs'},
          component: () => import('@/pages/mgmt/MongoLogsPage.vue'),
        },
        {
          path: 'rabbitmq',
          name: 'RabbitMQPage',
          meta: {activeMenu: 'rabbitmq'},
          component: () => import('@/pages/mgmt/RabbitMQPage.vue'),
        },
        {
          path: 'size-reports',
          name: 'SizeReportsPage',
          meta: {activeMenu: 'sizeReports'},
          component: () => import('@/pages/mgmt/SizeReportsPage.vue'),
        },
        {
          path: 'database-browser',
          name: 'DatabaseBrowserPage',
          meta: {activeMenu: 'databaseBrowser'},
          component: () => import('@/pages/mgmt/DatabaseBrowserPage.vue'),
        },
      ]
    },
    // Always leave this as last one,
    // but you can also remove it
    {
      path: '*',
      component: () => import('@/pages/ErrorPage')
    }
  ]

  return routes;
}

export {getRoutes};
