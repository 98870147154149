import {POST} from "@/services/gateway";

const listSnapshotDetails = ({userIds, folderId, snapshotDateTime, pagination, searchTerm}) => {
  return POST('google/gmail/search', {userIds, folderId, snapshotDateTime, pagination, searchTerm})
}

const listBreadcrumps = ({userId, folderId, snapshotDate}) => {
  return POST('google/gmail/breadcrumbs', {userId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds, outputFormat, code}) => {
  return POST('google/gmail/export', {snapshotDateTime, sourceIds, searchTerm, selectedIds, outputFormat}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, code}) => {
  return POST('google/gmail/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds}, {code: code ? code : null});
}

export {
  listSnapshotDetails,
  listBreadcrumps,
  exportSnapshot,
  restoreSnapshot
}
