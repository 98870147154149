const getAddressName = (value) => {

  const addressObj = JSON.parse(value)
  const addressFields = ['Street', 'PostalCode', 'City', 'State', 'CountryOrRegion', 'streetAddress', 'postalCode', 'city', 'country'];

  return addressFields.reduce((addressString, addressField) => {

    if (addressObj[addressField]) {
      return `${addressString} ${addressObj[addressField]},`
    }
    return addressString;

  }, '')
}

const getLocationName = (value) => {
  const locationObj = JSON.parse(value)

  if (locationObj && locationObj['DisplayName']) {
    return location && locationObj['DisplayName']
  }

  return 'NA'
}

const getStatusLabel = (value) => {

  if (value === 3) {
    return 'Completed'
  } else if (value === 4) {
    return 'Failed'
  }

  return '/'
}

const convertSize = (size) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0, n = parseInt(size, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return (n.toFixed(n < 10 && l > 0 ? 2 : 0) + ' ' + units[l]);
}

const getEllipsisStr = (str, characters = 6, charactersLength) => {
  if (str?.length > charactersLength) {
    return str.slice(0, characters) + '...' + str.slice(-characters)
  }
  return str
}

const formatCurrency = (value) => {
  if (typeof value !== 'number') {
    throw new TypeError("The value must be a number.");
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
};

const wrapDecimals = (value, numDecimals = 2) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: numDecimals
  }).format(value);
};

export {
  getAddressName,
  getLocationName,
  convertSize,
  getStatusLabel,
  getEllipsisStr,
  formatCurrency,
  wrapDecimals
}
