import {GET, POST} from "@/services/gateway";

const getStats = (data) => {
  const currentDate = new Date();
  data = {
    ...data,
    TimeZoneOffset: currentDate.getTimezoneOffset()
  }
  return POST('dashboard/stats/box', data)
}

const getFinishedBackup = () => {
  return GET('dashboard/box/ready')
}

export {
  getStats,
  getFinishedBackup
}
