import {list, refresh, create, deleteAdministrator} from "@/services/api/app/admin/administrator";
import {defaultPagination,} from "@/services/pagination";

const defaultState = () => ({
  administrators: [],
  administratorsMap: {},
  pagination: {
    ...defaultPagination(),
    sortDescending: false,
    sortBy: 'emailAddress'
  },
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}, pagination) {
      return list({pagination})
        .then(({result, pagination}) => {
          commit('setAdministrators', result)
          commit('setPagination', pagination)
        })
    },
    create({commit}, input) {
      return create(input)
    },
    deleteAdministrator({commit}, user) {
      return deleteAdministrator(user.userId)
    },
    refresh({commit}) {
      return refresh()
    },
  },

  mutations: {
    setAdministrators(state, administrators) {
      state.administrators = administrators;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = defaultPagination();
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },


  state: defaultState()
}
