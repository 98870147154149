import {GET, POST} from "@/services/gateway";

const list = ({pagination, search, filter}) => {
  return POST('seat/mailbox/list', {pagination, search, filter});
}

const getStatus = () => {
  return GET('seat/mailbox/status');
}

const refresh = () => {
  return GET('seat/mailbox/refresh');
}

const backupAll = () => {
  return GET('seat/mailbox/backup')
}

const protectAll = () => {
  return POST('seat/mailbox/protect');
}


const unprotectAll = () => {
  return POST('seat/mailbox/unprotect');
}

const changeStatus = ({status, id}) => {
  return POST('seat/mailboxstatus/change', {status, id});
}

const exportSnapshot = (filter, search, pagination) => {
  const currentDate = new Date();

  return POST('seat/mailbox/export', {filter, search, pagination, TimeZoneOffset: currentDate.getTimezoneOffset()})
}

export {
  list,
  refresh,
  getStatus,
  protectAll,
  unprotectAll,
  backupAll,
  changeStatus,
  exportSnapshot
}
