import {GET, POST} from "@/services/gateway";

const listUsers = ({pagination, search, filter}) => {
  return POST('google/user/list', {pagination, search, filter});
}

const getUserDetails = (id) => {
  return GET('google/gmail/user', {id})
}

const listSnapshots = ({userId, date, dateTo}) => {
  return POST('google/gmail/snapshot/list', {userId, date, dateTo})
}

const createBackup = (userId) => {
  return GET('google/gmail/backup', {userId})
}

const getEmailDetails = (id) => {
  return GET(`google/gmail/view?mailId=${id}`, )
}

export {
  listUsers,
  getUserDetails,
  listSnapshots,
  createBackup,
  getEmailDetails,
}
