import {GET, POST} from "@/services/gateway";

const listRestores = ({pagination, sourceType, dataTypes, dataProvider = 'MICROSOFT'}) => {
  return POST('recovery/restore/list', {pagination, sourceType, dataTypes, dataProvider})
}

const cancelRestore = (id, module) => {
  return GET(`${module}/restore/${id}/cancel`)
}

const cancelRestoreWithRecovery = (id, module) => {
  return GET(`recovery/${module}/restore/${id}/cancel`)
}

export {
  listRestores,
  cancelRestore,
  cancelRestoreWithRecovery
}
