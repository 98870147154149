import {GET, POST} from "@/services/gateway";

const listRestores = ({pagination, sourceType, dataTypes, dataProvider = 'GOOGLE'}) => {
  return POST('recovery/restore/list', {pagination, sourceType, dataTypes, dataProvider})
}

const cancelRestore = (id, module) => {
  return GET(`google/${module}/restore/${id}/cancel`)
}

export {
  listRestores,
  cancelRestore
}
