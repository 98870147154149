<template>
  <div class="i-user-multi-picker">
    <div class="field-label">
      {{ label }}
    </div>
    <multiselect
      v-model="selected"
      label="name"
      trackBy="userId"
      :options="users"
      :multiple="false"
      :placeholder="placeholder"
      @search-change="onSearch"
      @select="onSelect"
      @remove="onRemove"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {list as listMicrosoftUsers} from "@/services/api/app/seat-management/user";
import {listAllUsers} from "@/services/api/app/office/recovery/exchange/exchange";
import {list as listGoogleUsers} from "@/services/api/app/google/seat-management/user"
import {list as listDropboxUsers} from "@/services/api/app/dropbox/seat-management/user";
import {list as listBoxUsers} from "@/services/api/app/box/seat-management/user";
import {defaultPagination} from "@/services/pagination";

export default {
  name: 'IUserPicker',
  components: {Multiselect},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Users'
    },
    placeholder: {
      type: String,
      default: 'Search for users here'
    },
    filter: {
      type: Object,
      default: {
        "statuses": [
          1, 2, 4
        ]
      }
    },
    providerTenant: {
      type: String,
      default: 'MICROSOFT'
    },
    currentCollection: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      selected: null,
      users: []
    }
  },

  methods: {
    onSearch(searchTerm) {
      this.users = [];
      this.loadUsers(searchTerm)
    },
    loadUsers(search = null) {
      let list = listGoogleUsers
      if (this.providerTenant === 'MICROSOFT') {
        if (this.currentCollection === 'exchange') {
          list = listAllUsers;
        }
        list = listMicrosoftUsers
      } else if (this.providerTenant === 'DROPBOX') {
        list = listDropboxUsers
      } else if (this.providerTenant === 'BOX') {
        list = listBoxUsers
      }

      list({
        pagination: {
          ...defaultPagination(),
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: this.filter
      })
        .then(({result, pagination}) => {
          this.users = result;
        })
    },
    onSelect(data) {
      this.$emit('input', data)
    },
    onRemove(data) {
      this.$emit('input', null)
    }
  },

  created() {
    if (this.value?.status != 4) {
      this.selected = this.value;
    }

    this.loadUsers();
  },
}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
