import {
  getSharedDriveDetails,
  listSnapshots,
  createBackup,
  listSharedDrives,
  exportSnapshot,
  restoreSnapshot
} from "@/services/api/google/recovery/shared-drive/sharedDrive";
import {defaultPagination} from "@/services/pagination";
import files from "@/store/app/google/recovery/shared-drive/data-set/file";
import exports from "@/store/app/google/recovery/shared-drive/exports";
import restores from "@/store/app/google/recovery/shared-drive/restores";
import backups from "@/store/app/google/recovery/shared-drive/backups";
import user from "@/store/app/google/recovery/shared-drive/user";

const defaultState = () => ({
  sharedDrives: [],
  sharedDriveMap: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null
});

export default {
  namespaced: true,
  actions: {
    listSharedDrives({commit, state},) {
      return listSharedDrives({
        pagination: state.pagination,
        search: state.search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
        .then(({result, pagination}) => {
          commit('setSharedDrives', result)
          commit('setPagination', pagination)
        })
    },
    getSharedDriveDetails({commit}, sharedDriveId) {
      return getSharedDriveDetails(sharedDriveId)
        .then(sharedDrive => {
          commit('setSharedDrive', sharedDrive);
          return sharedDrive;
        })
    },
    createBackup({commit}, sharedDriveId) {
      return createBackup(sharedDriveId);
    },
    listSnapshots({commit}, {sharedDriveId, date, dateTo}) {
      return listSnapshots({sharedDriveId, date, dateTo});
    },
    exportSnapshot({commit}, data) {
      return exportSnapshot(data);
    },
    restoreSnapshot({}, data) {
      return restoreSnapshot(data);
    },
  },

  mutations: {
    setSharedDrives(state, sharedDrives) {
      state.sharedDrives = sharedDrives;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state, {keepSort}) {
      if (keepSort) {
        state.pagination = {
          ...defaultPagination(),
          sortBy: state.pagination.sortBy,
          sortDescending: state.pagination.sortDescending,
        }
      } else {
        state.pagination = {
          ...defaultPagination(),
          sortBy: 'name',
          sortDescending: false
        };
      }

    },
    setSearchFiles(state, search) {
      state.search = search;
    },
    setSharedDrive(state, sharedDrive) {
      state.sharedDriveMap = {
        ...state.sharedDriveMap,
        [sharedDrive.sharedDriveId]: sharedDrive
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  getters: {
    getSharedDriveById: (state) => (id) => {
      return state.sharedDriveMap[id];
    },
  },

  state: defaultState(),

  modules: {
    files,
    exports,
    restores,
    backups,
    user
  },
}
